import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { Api } from "../api/api";

const STATUS_IDLE = 'idle';
const STATUS_LOADING = 'loading';
const STATUS_SUCCEEDED = 'succeeded';
const STATUS_FAILED = 'failed';

const initialState = {
    status: STATUS_IDLE,
    save_dont_show_help_popup_status: STATUS_IDLE,
    error: null,

    locationsNotDefined: false,
    userLocations: [],
    showHelpPopup: false,
};

export const getUserWarehouseLocations = createAsyncThunk(
    'shop/getUserWarehouseLocations',
    async (_noParams, { getState }) => {

        const response = await Api.getUserWarehouseLocations();
        return response;
    }
)

export const saveDontShowHelpPopup = createAsyncThunk(
    'shop/saveDontShowHelpPopup',
    async (_noParams, { getState }) => {

        const response = await Api.saveDontShowHelpPopup();
        return response;
    }
)

export const slice = createSlice({
    name: 'locations',
    initialState,

    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        setShowHelpPopup: (state, action) => {
            state.showHelpPopup = action.payload;
        },
        setLocationsNotDefined: (state, action) => {
            state.locationsNotDefined = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder
            // GET USER WAREHOUSE LOCATIONS
            .addCase(getUserWarehouseLocations.pending, (state) => {
                state.status = STATUS_LOADING;
            })
            .addCase(getUserWarehouseLocations.fulfilled, (state, action) => {
                state.status = STATUS_SUCCEEDED;

                state.userLocations = action.payload.user_warehouselocations;
                state.locationsNotDefined = action.payload.locations_not_defined
            })
            .addCase(getUserWarehouseLocations.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })

            // SAVE DONT SHOW HELP POPUP
            .addCase(saveDontShowHelpPopup.pending, (state) => {
                state.save_dont_show_help_popup_status = STATUS_LOADING;
            })
            .addCase(saveDontShowHelpPopup.fulfilled, (state, action) => {
                state.save_dont_show_help_popup_status = STATUS_SUCCEEDED;

                state.showHelpPopup = false;
            })
            .addCase(saveDontShowHelpPopup.rejected, (state, action) => {
                state.save_dont_show_help_popup_status = STATUS_FAILED;
            })
    }
});

export const { setStatus, setShowHelpPopup, setLocationsNotDefined } = slice.actions;

export const selectIsLoadingUserLocations = state => state.locations.status !== STATUS_SUCCEEDED;
export const selectUserLocations = state => state.locations.userLocations;
// Show help popup if the user did not check to not show again or if his locations were removed
export const selectShowHelpPopup = state => state.settings.status === STATUS_SUCCEEDED && state.settings.settings.first_login_text !== '' &&
    state.locations.status === STATUS_SUCCEEDED && (state.locations.showHelpPopup || (state.locations.userLocations && state.locations.userLocations.length === 0 && !state.locations.locationsNotDefined));
// User has locations or locations are not defined
export const selectUserHasLocations = state => state.locations.status === STATUS_SUCCEEDED && 
    ((state.locations.userLocations && state.locations.userLocations.length > 0) || state.locations.locationsNotDefined);
export const selectUserHasOneLocation = state => state.locations.status === STATUS_SUCCEEDED && state.locations.userLocations.length === 1;
// Allow the user to move away from the login page if the user:
// checked the do not show again checkbox AND
// has locations selected OR locations are not defined
export const selectAllowNavigationFromLogin = state => state.locations.status === STATUS_SUCCEEDED && 
    (!state.locations.showHelpPopup || state.settings.settings.first_login_text === '') && 
    ((state.locations.userLocations && state.locations.userLocations.length > 0) || state.locations.locationsNotDefined);
export const selectLocationsNotDefined = state => state.locations.locationsNotDefined;
export const selectHasLocationsDefined = state => !state.locations.locationsNotDefined;

export const selectLocationSelected = state => {
    if (state.filters.location !== "0") {
        const selectedLocation = state.locations.userLocations.find(loc => loc.id === state.filters.location);
        if (selectedLocation) {
            return selectedLocation
        }
    }
    return false;
};

export default slice.reducer;