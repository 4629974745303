import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import {
    BrowserRouter,
} from "react-router-dom";

import { AuthProvider } from "./common/hooks/useAuth";
import QVRouter from "./routes/QVRouter";
import { AppTheme } from './common/ui/customisation/AppTheme';
import { Storage } from './common/utils/Storage';

import DateTimeFormatConfig from './common/utils/DateTimeFormatConfig';
import { ShopDatesInterval } from './common/utils/ShopDatesInterval';
import WorkingDays from './common/utils/WorkingDays';
import RecurrenceTypes from './common/ui/qvshop/components/recurringIntervalSelector/utils/RecurrenceTypes';
import RecurrenceDays from './common/ui/qvshop/components/recurringIntervalSelector/utils/RecurrenceDays';

import { getSettings, selectWorkingHours, selectSemesterSettings, selectOrganisationName, selectHasExtendedTimes } from "./common/store/settingsSlice";
import { getUserWarehouseLocations, selectUserHasLocations, selectUserLocations } from './common/store/locationsSlice';
import { getCategories } from './common/store/categoriesSlice';
import { 
    selectRecurrenceType,
    selectStartDate, 
    selectEndDate,

    setStartDate,
    setEndDate,
    changeRecurrenceType,
    setIsCurrentSemesterInProgress, 
    setIsCurrentSemesterNoClassesInProgress, 
    setHasNoClasses, 
    setStartDateRecurring,
    setEndDateRecurring,
    setStartPickerEnabledIntervals,
    setEndPickerEnabledIntervals,
    setStartPickerEnabledHoursForDay,
    setEndPickerEnabledHoursForDay,

    setLocation,

} from './common/store/filtersSlice';
import { getReturnKey, prepareReturnKeyData, setCart } from './common/store/cartSlice';

export default function App() {
    const dispatch = useDispatch();
    const token = Storage.getAuthToken();
    dayjs.locale('de');

    const organisationName = useSelector(selectOrganisationName);
    const workingHours = useSelector(selectWorkingHours);
    const semesterSettings = useSelector(selectSemesterSettings);
    const hasExtendedTimes = useSelector(selectHasExtendedTimes);

    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);

    const recurrenceType = useSelector(selectRecurrenceType);

    const userHasLocations = useSelector(selectUserHasLocations);
    const userLocations = useSelector(selectUserLocations);

    const storageLocationId = Storage.getLocationId();
    
    useEffect(() => {
        document.title = organisationName + ' Verleihshop';
    }, [organisationName]);

    useEffect(() => {
        // Load settings on each app start
        dispatch(getSettings());
    }, [dispatch]);

    // ANDY: Load locations and categories when the user loggs in (Update to not use only token for login check - look into ProtectedRoute)
    useEffect(() => {
        if(token)
        {
            dispatch(getUserWarehouseLocations());
        }
    }, [dispatch, token]);

    useEffect(() => {
        if(token)
        {
            const cartInStorage = Storage.getCart();
            dispatch(setCart(cartInStorage));
            if(hasExtendedTimes) {
                dispatch(prepareReturnKeyData());
            }
        }
    }, [dispatch, token, hasExtendedTimes]);

    // ANDY: If the user has only one location - set it as the selected location
    useEffect(() => {
        if(userHasLocations) {
            if(userLocations.length === 1){
                // Set the location id inside the local storage
                Storage.setLocationId(userLocations[0].id);
                dispatch(setLocation(userLocations[0].id));
            } else {
                const foundLocationFromStorageLocationId = userLocations.find(location => location.id === storageLocationId)

                // If we find this location from local stoarage insisde the userlocations - save it in state as the selected location
                if(foundLocationFromStorageLocationId?.id) {
                    dispatch(setLocation(foundLocationFromStorageLocationId.id));
                } else { // The user no longer has this location - change the value inside the local storage to default "0"
                    Storage.setLocationId("0");
                }
            }

            // After the location has been set load the categories and the return key
            dispatch(getCategories());
            dispatch(getReturnKey());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, userLocations, userHasLocations]);

    useEffect(() => {
        if( workingHours && semesterSettings ) {
            // Set the NON recurring initial dates and times for date-time pickers if not set
            const calculatedDates = ShopDatesInterval.get(workingHours, semesterSettings, 0, 0, startDate, endDate);
            dispatch(setStartDate(calculatedDates.startDate));
            dispatch(setEndDate(calculatedDates.endDate));

            // Set the initial dates for recurring
            let currentSemesterInProgress = WorkingDays.isTodayInSemesterOrInNoClassesInterval(semesterSettings);

            dispatch(setIsCurrentSemesterInProgress(currentSemesterInProgress.currentSemester));
            dispatch(setIsCurrentSemesterNoClassesInProgress(currentSemesterInProgress.currentNoClasses));
            dispatch(setHasNoClasses(currentSemesterInProgress.hasNoClasses));

            if (recurrenceType === null) {
                // Select current semester if we are inside a semester interval
                if(currentSemesterInProgress.currentSemester) {
                    dispatch(changeRecurrenceType(RecurrenceTypes.RECURRENCE_TYPE_CURRENT_SEMESTER));
                } else if(currentSemesterInProgress.currentNoClasses) { // Select current NO classes if we are inside a no classes interval
                    dispatch(changeRecurrenceType(RecurrenceTypes.RECURRENCE_TYPE_CURRENT_NO_CLASSES));
                } else { // If we are outside any of the intervals select the next interval
                    dispatch(changeRecurrenceType(RecurrenceTypes.RECURRENCE_TYPE_NEXT_SEMESTER));
                }
            }

            // Set the initial dates for recurring interval
            if(recurrenceType !== null) {
                const calculatedDates = ShopDatesInterval.get(workingHours, semesterSettings, 1/* recurring */, recurrenceType);
                dispatch(setStartDateRecurring(calculatedDates.startDate));
                dispatch(setEndDateRecurring(calculatedDates.endDate));
            }

            const disabledDaysPickup = WorkingDays.generateEnabledIntervalsForNextYears(workingHours, semesterSettings, WorkingDays.getPickupDropOff(true));
            dispatch(setStartPickerEnabledIntervals(disabledDaysPickup));
            const disabledDaysDropoff = WorkingDays.generateEnabledIntervalsForNextYears(workingHours, semesterSettings, WorkingDays.getPickupDropOff(false));
            dispatch(setEndPickerEnabledIntervals(disabledDaysDropoff));

            const timesForPickupSelectedDate = WorkingDays.prepareTimeSliderDataForDay(workingHours, semesterSettings, DateTimeFormatConfig.getDateFromStandardPickerDateFormat(calculatedDates.startDate), RecurrenceDays.WORKING_HOURS_KEY_FOR_PICKUP);
            dispatch(setStartPickerEnabledHoursForDay(timesForPickupSelectedDate));
            const timesForDropoffSelectedDate = WorkingDays.prepareTimeSliderDataForDay(workingHours, semesterSettings, DateTimeFormatConfig.getDateFromStandardPickerDateFormat(calculatedDates.endDate), RecurrenceDays.WORKING_HOURS_KEY_FOR_DROPOFF);
            dispatch(setEndPickerEnabledHoursForDay(timesForDropoffSelectedDate));
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workingHours, semesterSettings]);

    useEffect(() => {
        if( workingHours && semesterSettings ) {
            const endDateAsDate = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(endDate);
            const calculatedDates = ShopDatesInterval.get(workingHours, semesterSettings, 0, 0, startDate, endDateAsDate.isValid() ? endDate : null);

            // dispatch(setStartDate(calculatedDates.startDate));
            dispatch(setEndDate(calculatedDates.endDate));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workingHours, semesterSettings, startDate]);

    return (
        <AppTheme>
            <BrowserRouter>
                <AuthProvider>
                    <QVRouter />
                </AuthProvider>
            </BrowserRouter>
        </AppTheme>
    );
}