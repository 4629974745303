export class Storage {

    static getBool(key)
    {
        // local storage stores as string
        let check = localStorage.getItem(key);
        return (check !== null) ? check === "true" : false; 
    }

    static setLocal(key, value) {

        localStorage.setItem(key, JSON.stringify(value));
    }

    static getLocal(key) {

        return JSON.parse(localStorage.getItem(key));
    }

    static setSession(key, value) {

        sessionStorage.setItem(key, value);
    }

    static getSession(key) {

        return sessionStorage.getItem(key);
    }

    static clearLocal() {

        localStorage.clear();
    }

    static clearSession() {

        sessionStorage.clear();
    }

    static clear() {

        this.clearLocal();
        this.clearSession();
    }

    static logoutStorage() {
        localStorage.removeItem('loggedin');
        localStorage.removeItem('auth_token');
        localStorage.removeItem('isIAMUser');
    }

    static setLoggedIn(token, isIAMUser, clearCart = true) {
        localStorage.setItem('loggedin', true);

        localStorage.setItem('auth_token', token);
        localStorage.setItem('isIAMUser', isIAMUser);

        if(clearCart) {
            localStorage.setItem("cart", JSON.stringify([]));
        }
    }

    static setLocationId(locationId = "0") {
        localStorage.setItem('locationId', locationId);
    }

    static isLoggedIn()
    {
        return this.getBool('loggedin');
    }

    static isIAMUser()
    {
        return this.getBool('isIAMUser');
    }

    static getAuthToken()
    {
        return localStorage.getItem("auth_token");
    }

    static getLocationId()
    {
        return localStorage.getItem("locationId");
    }

    static getCart()
    {
        let cart = localStorage.getItem("cart");  
        return cart ? JSON.parse(cart) : [];
    }

    static setCart(cart = [])
    {
        localStorage.setItem("cart", JSON.stringify(cart));
    }
}