import DateTimeFormatConfig from "../../utils/DateTimeFormatConfig";
import { AvailabilityUtils } from "./AvailabilityUtils";

export class CheckoutUtils {
    static prepareDataForReservation(state) {
        const cart = state.cart.cart;
        const telephone = state.checkout.userDetails.ruckrufnr;
        const pickupPersonId = state.checkout.userDetails.studierenden_nr;
        const emailLecturer = state.checkout.email_lecturer;
        const teacher = state.checkout.teacher;
        const usageText = state.checkout.usage_text;
        const dataForAvailability = AvailabilityUtils.prepareAvailabilityDataForProducts(cart, state);

        let payload = {
            products       : {},
            recurring_products : {},
            location_id    : state.filters.location,
            studierenden_nr: pickupPersonId,
            teacher        : teacher,
            email_lecturer : emailLecturer,
            usage_text     : usageText,
            ruckrufnr      : telephone,
            availabilityData: dataForAvailability,
            create_contract: "MGmLha323kd3"
        };

        for (let item in cart) {
            const cartItem = {...cart[item]};

            let itemId = cartItem.product_id || cartItem.item_id || cartItem.id ;
            if(typeof(itemId) === "string") {
                itemId = itemId.trim();
            }

            // Configurable item - get data from subarticles
            if(cartItem.subarticles && Object.keys(cartItem.subarticles).length) {
                for (const [id, subarticle] of Object.entries(cartItem.subarticles)) {
                    const subId = subarticle.id;
                    const subIsRecurring = subarticle.recurring === 1 ? true : false;

                    const subarticleData = CheckoutUtils.prepareItemDataForReservation(subarticle);
                    if(subIsRecurring) {
                        const subForReservation = subarticleData.recurring_products[subId];
                        payload.recurring_products[itemId+';'+subId] = subForReservation;
                    } else {
                        const subForReservation = subarticleData.products[subId];
                        payload.products[itemId+';'+subId] = subForReservation;
                    }
                }
            } else {
                const cartItemRecurring = cartItem.recurring === 1 || (cartItem.isReturnKey && cartItem.occurence) ? true : false;

                let productData = CheckoutUtils.prepareItemDataForReservation(cartItem);
                if(cartItemRecurring) {
                    const productForReservation = productData.recurring_products[itemId];
                    payload.recurring_products[itemId] = productForReservation;
                } else {
                    const productForReservation = productData.products[itemId];
                    payload.products[itemId] = productForReservation;
                }
            }
        }
        console.log("Payload final: ", payload);

        return payload;
    }

    static prepareItemDataForReservation(cartItem) {
        let itemPayload = {
            recurring_products: {},
            products: {}
        };

        let itemId = cartItem.product_id || cartItem.item_id || cartItem.id ;
        if(typeof(itemId) == "string") {
            itemId = itemId.trim();
        }

        if (cartItem.recurring || (cartItem.isReturnKey && cartItem.occurence && cartItem.occurence.length)) {
            itemPayload.recurring_products[itemId]= [];
            cartItem.occurence.forEach((occ , occIndex) => {
                let occurencePayload = {
                    id        : itemId,
                    quantity  : occ.quantity || 1,
                    start_date: DateTimeFormatConfig.standardServerDateFormat(occ.startDate),
                    end_date  : DateTimeFormatConfig.standardServerDateFormat(occ.endDate)
                };

                itemPayload.recurring_products[itemId].push(occurencePayload);
            })
        } else {
            itemPayload.products[itemId] = {
                id        : itemId,
                quantity  : cartItem.quantity || 1,
                start_date: DateTimeFormatConfig.standardServerDateFormat(cartItem.startDate),
                end_date  : DateTimeFormatConfig.standardServerDateFormat(cartItem.endDate)
            };
        }

        return itemPayload;
    }
}