import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';

import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';

import { flexRowCentered } from "../../sx/common";

import TextField from './TextField';
import FromToInterval from './FromToInterval';
import RecurringIntervalSelector from './recurringIntervalSelector/RecurringIntervalSelector';
import PrimaryButton from './core/PrimaryButton';
import RequestPeriodTabs from './RequestPeriodTabs';

import { addItemToCart, selectDisabledNonRecurringTab, selectDisabledRecurringTab } from '../../../store/cartSlice';
import {
    selectFromToHasErrors,
    selectOutsideWorkingHoursDropoff,
    selectOutsideWorkingHoursDropoffError,
    selectOutsideWorkingHoursPickup,
    selectOutsideWorkingHoursPickupError,
    selectRecurrenceHasErrors,
    selectRecurring
} from '../../../store/filtersSlice';
import { AvailabilityUtils } from '../../../store/utils/AvailabilityUtils';
import { selectShowRecurrentTab } from '../../../store/settingsSlice';

export default function ReservationSection(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const showRecurrentTab = useSelector(selectShowRecurrentTab);
    const recurringState = useSelector(selectRecurring);
    const hasRecurrenceErrorsState = useSelector(selectRecurrenceHasErrors);
    const outsideWorkingHoursPickupState = useSelector(selectOutsideWorkingHoursPickup);
    const outsideWorkingHoursPickupErrorState = useSelector(selectOutsideWorkingHoursPickupError);
    const outsideWorkingHoursDropoffState = useSelector(selectOutsideWorkingHoursDropoff);
    const outsideWorkingHoursDropoffErrorState = useSelector(selectOutsideWorkingHoursDropoffError);

    const hasFromToErrorsState = useSelector(selectFromToHasErrors);

    const nonRecurringTabDisabled = useSelector(selectDisabledNonRecurringTab);
    const recurringTabDisabled = useSelector(selectDisabledRecurringTab);

    const {
        direction,
        item = false,
        subarticle = false,
        isCartItem = false,
        showQuantity = false,
        onChangeQuantity = false,
        showAddToCartButton = false,
        showIntervalSelection = true,
        loadingAvailability = false,
        withAvailability = true
    } = props;

    const quantity = item && item.quantity ? item.quantity : "";

    const isRecurring = subarticle?.recurring ?? item?.recurring ?? recurringState;
    const itemAvailability = subarticle?.available ?? item?.available ?? false;
    const { available, addToCartButtonLabel } = AvailabilityUtils.handleAvailabilityResponse(itemAvailability);

    const hasFromToErrors = subarticle?.hasFromToErrors ?? item?.hasFromToErrors ?? hasFromToErrorsState;
    const hasRecurrenceErrors = subarticle?.hasRecurrenceErrors ?? item?.hasRecurrenceErrors ?? hasRecurrenceErrorsState;

    const outsideWorkingHoursPickup = subarticle?.outsideWorkingHoursPickup ?? item?.outsideWorkingHoursPickup ?? outsideWorkingHoursPickupState;
    const outsideWorkingHoursPickupError = subarticle?.outsideWorkingHoursPickupError ?? item?.outsideWorkingHoursPickupError ?? outsideWorkingHoursPickupErrorState;
    const outsideWorkingHoursDropoff = subarticle?.outsideWorkingHoursDropoff ?? item?.outsideWorkingHoursDropoff ?? outsideWorkingHoursDropoffState;
    const outsideWorkingHoursDropoffError = subarticle?.outsideWorkingHoursDropoffError ?? item?.outsideWorkingHoursDropoffError ?? outsideWorkingHoursDropoffErrorState;

    const showTabsSelector = showRecurrentTab && showIntervalSelection;

    const shouldDisableAddToCart = (!!(!isRecurring && hasFromToErrors) || !!(isRecurring && hasRecurrenceErrors) || loadingAvailability || !available);

    const handleQuantityChange = (event) => {
        const newValue = event.target.value;
        onChangeQuantity(newValue);
    }

    const handleAddToCart = () => {
        dispatch(addItemToCart(item, subarticle));
    }

    useEffect(() => {
        let snackbarMessage = "";
        if (outsideWorkingHoursPickup && outsideWorkingHoursDropoff && outsideWorkingHoursPickupError !== '' && outsideWorkingHoursDropoffError !== '') {
            snackbarMessage = outsideWorkingHoursPickupError + "\n" + outsideWorkingHoursDropoffError
        } else {
            snackbarMessage = outsideWorkingHoursPickup ? outsideWorkingHoursPickupError : outsideWorkingHoursDropoffError;
        }

        if (isMobile && hasRecurrenceErrors) {
            (outsideWorkingHoursPickup || outsideWorkingHoursDropoff) && (snackbarMessage !== '') && enqueueSnackbar(snackbarMessage, {
                variant: 'error',
                preventDuplicate: true,
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                style: { whiteSpace: 'pre-line' }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile, item, subarticle, hasRecurrenceErrors, outsideWorkingHoursPickup, outsideWorkingHoursDropoff])

    return (
        <Stack direction='column' >
            {showTabsSelector && !(isCartItem && (nonRecurringTabDisabled || recurringTabDisabled)) &&
                <Box sx={{ pb: 3 }}>
                    <RequestPeriodTabs item={item} subarticle={subarticle} isCartItem={isCartItem} />
                </Box>}

            <Stack direction={direction ?? { xs: 'column', md: (isRecurring ? 'column' : 'row') }} spacing={2}>
                {showIntervalSelection &&
                    <Box>
                        {isRecurring ?
                            <RecurringIntervalSelector direction={direction} item={item} subarticle={subarticle} isCartItem={isCartItem} /> :
                            <FromToInterval direction={direction} withAvailability={withAvailability} item={item} subarticle={subarticle} isCartItem={isCartItem} />}
                    </Box>
                }
            
                <Stack direction='row' spacing={2} alignItems={showQuantity ? "baseline" : "center"}>
                    {showQuantity && <TextField sx={{ width: 100 }}
                        forceHelperText={isRecurring}
                        name="quantity"
                        label={t("input.label.quantity")}
                        id="quantity"
                        value={quantity}
                        onChange={handleQuantityChange}
                    />}

                    {(showAddToCartButton || (shouldDisableAddToCart && isCartItem && !(!!(!isRecurring && hasFromToErrors) || !!(isRecurring && hasRecurrenceErrors)))) &&
                        <Box sx={flexRowCentered}>
                            <PrimaryButton disabled={shouldDisableAddToCart} label={t(loadingAvailability ? "button.addToCartLoadingAvailability" : addToCartButtonLabel)} onClick={handleAddToCart} />
                        </Box>
                    }
                </Stack>
            </Stack>
        </Stack>
    );
};