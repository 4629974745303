import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Box, Container, Stack, Typography } from '@mui/material';

import { notScrollable, flexColFullHeightScrollable, p1 } from '../common/ui/sx/common';

import { usePageLayoutConfigurator } from '../common/hooks/usePageLayoutConfigurator';
import PageNames from '../common/utils/PageNames';
import { NavigationUtils } from '../common/utils/NavigationUtils';

import PrimaryButton from '../common/ui/qvshop/components/core/PrimaryButton';

import { selectMakeReservationSuccessfull, setMakeResevationStatusIdle } from '../common/store/checkoutSlice';
import { selectHasProduct, selectProduct } from '../common/store/productSlice';
import { selectCategory } from '../common/store/filtersSlice';

export default function RentalProcessFinished() {
    usePageLayoutConfigurator(PageNames.RENTAL_PROCESS_FINISHED);
    const { t } = useTranslation(['common', 'rentalProcessFinished']);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const makeReservationSuccessfull = useSelector(selectMakeReservationSuccessfull);
    const hasVisitedProductDetailsPage = useSelector(selectHasProduct);
    const lastVisitedProduct = useSelector(selectProduct);
    const selectedCategory = useSelector(selectCategory);

    useEffect(() => {
        if(!makeReservationSuccessfull) {
            // Navigate to previous page. What should be the previous page????
            // navigate(-1); - not the right solution - the previous page might be checkout or any other unavailable page(eg. login page)
            // So the better solutin would be to check if the user has been on any product details page before and go there or if not then go to the products list page
            navigate(NavigationUtils.navigateToProductDetailsOrProductsList(hasVisitedProductDetailsPage, lastVisitedProduct, selectedCategory), { replace: true });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, makeReservationSuccessfull])

    const handleRentalProcessFinishedAcknoledged = () => {
        // Set the make reservation status back to idle
        dispatch(setMakeResevationStatusIdle());
        navigate("/items");
    }

    return (
        <Container sx={{...flexColFullHeightScrollable, ...notScrollable, ...p1}} component="main" maxWidth="sm" >
            <Stack direction="column" spacing={3} alignItems="center">
                <Typography variant="h5">{t("rentalProcessFinished:title")}</Typography>
                <Stack direction="column" spacing={2} alignItems="center">
                    <Stack direction="column" spacing={0} alignItems="center">
                        <Typography align="center" variant="body1">{t("rentalProcessFinished:text1")}</Typography>
                        <Typography align="center" variant="body1">{t("rentalProcessFinished:text2")}</Typography>
                    </Stack>
                    <Box>
                        <PrimaryButton label={t("rentalProcessFinished:button.toShop")} onClick={handleRentalProcessFinishedAcknoledged} />
                    </Box>
                </Stack>
            </Stack>
        </Container>
    );
};