import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { Stack } from '@mui/material';

import Select from '../../Select';
import FromToInterval from '../../FromToInterval';

import RecurrenceTypes from '../utils/RecurrenceTypes';

import { 
    selectRecurrenceType, 
    selectIsCurrentSemesterInProgress,
    selectIsCurrentSemesterNoClassesInProgress,
    selectHasNoClasses,
    selectStartDateRecurring, 
    selectEndDateRecurring,

    changeRecurrenceType,
} from '../../../../../store/filtersSlice';

import { updateProduct } from '../../../../../store/productSlice';
import { updateCartItem } from '../../../../../store/cartSlice';
import { selectAllArticlesMustBeInTheSameInterval } from '../../../../../store/settingsSlice';

export default function RecurrenceTypeSelect(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { direction, item = false, subarticle = false, isCartItem = false } = props;

    const allArticlesMustBeInTheSameInterval = useSelector(selectAllArticlesMustBeInTheSameInterval);

    const isCurrentSemesterInProgress = useSelector(selectIsCurrentSemesterInProgress);
    const isCurrentSemesterNoClassesInProgress = useSelector(selectIsCurrentSemesterNoClassesInProgress);
    const hasNoClasses = useSelector(selectHasNoClasses);

    const recurrenceTypeState = useSelector(selectRecurrenceType);
    const startDateRecurringState = useSelector(selectStartDateRecurring);
    const endDateRecurringState = useSelector(selectEndDateRecurring);

    let recurrenceType = subarticle?.recurrenceType ?? item?.recurrenceType ?? recurrenceTypeState;
    let startDateRecurring = subarticle?.startDateRecurring ?? item?.startDateRecurring ?? startDateRecurringState;
    let endDateRecurring = subarticle?.endDateRecurring ?? item?.endDateRecurring ?? endDateRecurringState;

    const fromToShown = recurrenceType === RecurrenceTypes.RECURRENCE_TYPE_FROM_UNTIL;

    const handleRecurrenceTypeChange = (event) => {
        const newValue = event.target.value;
        if(item) {
            if(isCartItem) {
                dispatch(updateCartItem({item, subarticle, field: "recurrenceType", value: newValue}));
            } else {
                dispatch(updateProduct({item, subarticle, field: "recurrenceType", value: newValue}));
            }
            
            if(allArticlesMustBeInTheSameInterval) {
                dispatch(changeRecurrenceType(newValue));
            }
        } else {
            dispatch(changeRecurrenceType(newValue));
        }
    };

    return (
        <Stack direction={direction ?? { xs: 'column', md: 'row' }} spacing={{ xs: 2, sm: 2 }} width={1}>
            <Select 
                sx={{width: "100%", minWidth: "250px"}}
                id="semesterType"
                label={t("sidebar.tab.recurring.semester.select.label")}
                value={recurrenceType}
                onChange={handleRecurrenceTypeChange}
                options={[
                    {id: RecurrenceTypes.RECURRENCE_TYPE_CURRENT_SEMESTER, name: t("select.options.recurring.semester.current"), disabled: !isCurrentSemesterInProgress}, 
                    {id: RecurrenceTypes.RECURRENCE_TYPE_CURRENT_NO_CLASSES, name: t("select.options.recurring.semester.currentNoClasses"), disabled: !hasNoClasses || !isCurrentSemesterNoClassesInProgress},
                    {id: RecurrenceTypes.RECURRENCE_TYPE_NEXT_SEMESTER, name: t("select.options.recurring.semester.next")},
                    {id: RecurrenceTypes.RECURRENCE_TYPE_NEXT_NO_CLASSES, name: t("select.options.recurring.semester.nextNoClasses"), disabled: !hasNoClasses},
                    {id: RecurrenceTypes.RECURRENCE_TYPE_FROM_UNTIL, name: t("select.options.recurring.semester.fromUntil")},
                ]}
                helperText={t("datetimepicker.from") + " " + startDateRecurring + " " + t("datetimepicker.until") + " " + endDateRecurring}
            />
            { fromToShown && <FromToInterval isRecurrenceFromToSelector item={item} subarticle={subarticle} isCartItem={isCartItem} withTime={false} direction={direction ?? { xs: 'column', md: 'row' }} fromDate={startDateRecurring} toDate={endDateRecurring} /> }
        </Stack>
    );
};