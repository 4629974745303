import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { ChevronRight, ExpandMore } from '@mui/icons-material';

import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';

import { CategoriesUtils } from '../../../utils/CategoriesUtils';

import { selectCategories } from '../../../store/categoriesSlice';
import { selectCategory, selectCategoryPath, setCategoryPath } from '../../../store/filtersSlice';

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(3),
        borderBottomRightRadius: theme.spacing(3),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.MuiSvgIcon-root': {
            color: `var(--tree-view-color, ${theme.palette.primary.main})`,
            fill: `var(--tree-view-color, ${theme.palette.primary.main})`,
        },
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        //   color: `var(--tree-view-color, ${theme.palette.primary.main})`,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: `var(--tree-view-color, ${theme.palette.primary.main})`,
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
        minHeight: 40,
        [theme.breakpoints.up('sm')]: {
            minHeight: "unset",
            borderTopRightRadius: theme.spacing(2),
            borderBottomRightRadius: theme.spacing(2),
        }
    },
}));

function StyledTreeItem(props) {
    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        ...other
    } = props;

  return (
        <StyledTreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                    { LabelIcon && <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} /> }
                    <Typography variant="body1" sx={{ flexGrow: 1 }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            {...other}
        />
  );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
};

export default function CategoriesTree() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const categories = useSelector(selectCategories);
    const selectedCategory = useSelector(selectCategory);
    const selectedCategoryPath = useSelector(selectCategoryPath);
    const expandedNodes = selectedCategoryPath.map(elem => elem.id);

    const handleCategorySelected = (event, nodeId) => {
        dispatch(setCategoryPath(CategoriesUtils.prepareCategoryPath(categories, nodeId)));
        navigate("/items/"+nodeId);
    }
    
    const renderTree = (categoryObj) => {
        const categoryData = Array.isArray(categoryObj) ? categoryObj[0] : categoryObj;
        const category = categoryData.Category;
        const children = categoryData.children;

        return (
            <StyledTreeItem key={category.idkat} nodeId={category.idkat} labelText={category.kat}>
                {Array.isArray(children)
                ? children.map((childCategory) => renderTree(childCategory))
                : null}
            </StyledTreeItem>
        );
    }

    return (
        <TreeView
            aria-label="categories"
            defaultExpanded={[]}
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            defaultEndIcon={<div style={{ width: 24 }} />}
            sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
            expanded={expandedNodes}
            selected={selectedCategory}
            onNodeSelect={handleCategorySelected}
            >
                { Object.keys(categories).length !== 0 && categories.map(renderTree) }
        </TreeView>
    );
}
